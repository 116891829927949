import { Controller } from "@hotwired/stimulus"
import Uploader from "../helpers/uploader"
import PicturePreviewCache from "../helpers/picture_preview_cache"

export default class extends Controller {
  static targets = [
    "fileIdentifierInput",
    "originalFilenameInput",
    "chunksCountInput",
    "form",
    "dropzone",
    "preview",
    "exceededMaxFileSizeMessage"
  ]

  static values = {
    options: String,
    maxPictureSize: Number
  }

  connect() {
    this.PICTURE_FILE_EXTENSIONS = ["jpg", "jpeg", "png"]
    this.MAX_FILE_SIZE = this.maxPictureSizeValue
    this.options = JSON.parse(this.optionsValue)
    this.picturePreviewCache = PicturePreviewCache
    this.initUploader()
    this.bindEvents()
  }

  initUploader() {
    this.uploader = new Uploader({
      dropzone: this.dropzoneTarget,
      uploadPath: this.options.uploadPath,
      fileType: this.PICTURE_FILE_EXTENSIONS,
      maxFileSize: this.MAX_FILE_SIZE,
      formAuthToken: this.options.formAuthToken,
      userId: this.options.userId
    })
  }

  bindEvents() {
    this.dropzoneTarget.addEventListener('start', this.onUploadStart.bind(this))
    this.dropzoneTarget.addEventListener('complete', this.onUploadComplete.bind(this))
    this.dropzoneTarget.addEventListener("exceeded-max-file-size", this.onExceededMaxFileSize.bind(this))
    this.uploader.init()
  }

  onUploadStart(event) {
    let data = event.detail
    this.addUploaderPlaceholder()
    this.setUploadParams(data.resumableFile)
    this.showPicturePreview(data.resumableFile)
    this.dropzoneTarget.remove()
    this.exceededMaxFileSizeMessageTarget.classList.add("hidden")
    this.stateUploading()
  }

  onUploadComplete(event, data) {
    this.stateUploadCompleted()
    this.element.closest("ul").dispatchEvent(new CustomEvent("picture-added", { bubbles: true }))
  }

  onExceededMaxFileSize(event, data) {
    this.exceededMaxFileSizeMessageTarget.classList.remove("hidden")
  }

  addUploaderPlaceholder() {
    const line = this.element.closest("li")
    const clone = line.cloneNode(true)
    line.parentNode.insertBefore(clone, line.nextSibling)
    this.application.getControllerForElementAndIdentifier(clone, "picture-upload") // Initialize the new element
  }

  setUploadParams(resumableFile) {
    this.fileIdentifierInputTarget.value = resumableFile.uniqueIdentifier
    this.originalFilenameInputTarget.value = resumableFile.fileName
    this.chunksCountInputTarget.value = resumableFile.chunks.length
  }

  showPicturePreview(resumableFile) {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(resumableFile.file)
    let self = this

    fileReader.onload = (event) => {
      self.previewTarget.style.backgroundImage = `url(${event.target.result})`
      self.picturePreviewCache.add(resumableFile.uniqueIdentifier, event.target.result)
    }
  }

  removeUnpersistedPicture(event) {
    event.currentTarget.closest('li').remove()
  }

  stateUploading() {
    this.element.classList.add("uploading")
  }

  stateUploadCompleted() {
    this.element.classList.add("uploaded")
    this.element.classList.remove("uploading")
  }
}

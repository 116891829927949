import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["linkList", "formContainer"]
  static values = {
    originalHtml: String
  }

  connect() {
    const select = this.element.querySelector('[data-role="link-type"]')
    if (select) {
      this.linkTypeChanged({ target: select })
    }
  }

  async editLink(event) {
    event.preventDefault()
    const link = event.target
    const listItem = link.closest('.link-list')

    this.originalHtmlValue = listItem.outerHTML

    try {
      const response = await fetch(link.href, {
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })

      if (response.ok) {
        const formHtml = await response.text()
        listItem.outerHTML = formHtml

        const newSelect = listItem.querySelector('[data-role="link-type"]')
        if (newSelect) {
          this.linkTypeChanged({ target: newSelect })
        }
      }
    } catch (error) {
      console.error('Error loading edit form:', error)
    }
  }

  cancelEdit(event) {
    event.preventDefault()
    const listItem = event.target.closest('.link-list')
    if (this.originalHtmlValue) {
      listItem.outerHTML = this.originalHtmlValue
    }
  }

  async submitForm(event) {
    event.preventDefault()

    const form = event.target
    const formData = new FormData(form)
    const methodInput = form.querySelector('input[name="_method"]')
    const isEdit = methodInput?.value === 'patch'
    const listItem = isEdit ? form.closest('.link-list') : null

    try {
      const response = await fetch(form.action, {
        method: form.method || 'POST',
        body: formData,
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })

      const responseHtml = await response.text()

      if (response.ok) {
        if (isEdit && listItem) {
          listItem.outerHTML = responseHtml

          const newListItem = this.element.querySelector('.link-list')
          if (newListItem) {
            const select = newListItem.querySelector('[data-role="link-type"]')
            if (select) {
              this.linkTypeChanged({ target: select })
            }
          }
        } else {
          const tempContainer = document.createElement('div')
          tempContainer.innerHTML = responseHtml
          const newLinkList = tempContainer.querySelector('[data-links-form-target="linkList"]')
          if (newLinkList) {
            this.linkListTarget.innerHTML = newLinkList.innerHTML

            try {
              const formResponse = await fetch('/admin/profile/links/new', {
                headers: {
                  'Accept': 'text/html',
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                }
              })

              if (formResponse.ok) {
                const formHtml = await formResponse.text()
                this.formContainerTarget.innerHTML = formHtml

                const select = this.formContainerTarget.querySelector('[data-role="link-type"]')
                if (select) {
                  this.linkTypeChanged({ target: select })
                }
              }
            } catch (error) {
              console.error('Error fetching fresh form:', error)
            }
          }
        }
      } else if (response.status === 422) {
        if (isEdit && listItem) {
          listItem.outerHTML = responseHtml
          const newListItem = this.element.querySelector('.link-list')
          if (newListItem) {
            const select = newListItem.querySelector('[data-role="link-type"]')
            if (select) {
              this.linkTypeChanged({ target: select })
            }
          }
        } else {
          const formContainer = this.formContainerTarget
          formContainer.innerHTML = responseHtml

          const select = formContainer.querySelector('[data-role="link-type"]')
          if (select) {
            this.linkTypeChanged({ target: select })
          }

          const newForm = formContainer.querySelector('form')
          if (newForm) {
            Array.from(formData.entries()).forEach(([name, value]) => {
              const input = newForm.querySelector(`[name="${name}"]`)
              if (input && input.type !== 'file') {
                input.value = value
              }
            })
          }
        }
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  linkTypeChanged(event) {
    const select = event.target

    const typesRequiringName = JSON.parse(select.dataset.typesRequiringName || '[]')
    const typesRequiringImage = JSON.parse(select.dataset.typesRequiringImage || '[]')

    const nameRequired = typesRequiringName.includes(select.value)
    const imageRequired = typesRequiringImage.includes(select.value)

    const form = select.closest("form")
    const nameInput = form.querySelector("input[data-role=link-name]")
    const imageInput = form.querySelector("input[data-role=link-image]")

    if (nameInput) {
      nameInput.style.display = nameRequired ? 'block' : 'none'
    }

    if (imageInput) {
      imageInput.style.display = imageRequired ? 'block' : 'none'
    }
  }

  async deleteLink(event) {
    event.preventDefault()

    if (!confirm(event.target.dataset.turboConfirm)) {
      return
    }

    const link = event.target

    try {
      const response = await fetch(link.href, {
        method: 'DELETE',
        headers: {
          'Accept': 'text/html',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })

      if (response.ok) {
        const linkContainer = link.closest('.link-list')
        if (linkContainer) {
          linkContainer.remove()
        }

        if (this.linkListTarget.querySelector('ul').children.length === 0) {
          this.linkListTarget.querySelector('ul').innerHTML = '<li class="text-gray-500">No links added yet</li>'
        }
      } else {
        console.error('Failed to delete link:', response.status)
      }
    } catch (error) {
      console.error('Error deleting link:', error)
    }
  }
}

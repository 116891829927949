/* eslint-disable no-undef */
import { application } from "./application"

import CollapseController from "./collapse_controller"
Stimulus.register("collapse", CollapseController)

import DialogController from "./dialog_controller"
Stimulus.register("dialog", DialogController)

import DropdownController from "./dropdown_controller"
Stimulus.register("dropdown", DropdownController)

import PlayerController from "./player_controller"
Stimulus.register("player", PlayerController)

import PlayableController from "./playable_controller"
Stimulus.register("playable", PlayableController)

import TabsController from "./tabs_controller"
Stimulus.register("tabs", TabsController)

import PlayingNowIndicatorController from "./playing_now_indicator_controller"
Stimulus.register("playing-now-indicator", PlayingNowIndicatorController)

import CarouselController from "./carousel_controller"
Stimulus.register("carousel", CarouselController)

import PlaylistController from "./playlist_controller"
Stimulus.register("playlist", PlaylistController)

import FavouriteController from "./favourite_controller"
Stimulus.register("favourite", FavouriteController)

import BookingRequestController from "./booking_request_controller"
Stimulus.register("booking-request", BookingRequestController)

import SelectAllController from "./select_all_controller"
Stimulus.register("select-all", SelectAllController)

import SearchFiltersController from "./search_filters_controller"
Stimulus.register("search-filters", SearchFiltersController)

import BadgeController from "./badge_controller"
Stimulus.register("badge", BadgeController)

import AddToPlaylistController from "./add_to_playlist_controller"
Stimulus.register("add-to-playlist", AddToPlaylistController)

import AddToPlaylistPopupController from "./add_to_playlist_popup_controller"
Stimulus.register("add-to-playlist-popup", AddToPlaylistPopupController)

import CreatePlaylistController from "./create_playlist_controller"
Stimulus.register("create-playlist", CreatePlaylistController)

import MapsController from "./maps_controller"
Stimulus.register("maps", MapsController)

import NotificationController from "./notification_controller"
Stimulus.register("notification", NotificationController)

import ThemeController from "./theme_controller"
Stimulus.register("theme", ThemeController)

import EmbedSizesController from "./embed_sizes_controller"
Stimulus.register("embed-sizes", EmbedSizesController)

import AutoSelectController from "./auto_select_controller"
Stimulus.register("auto-select", AutoSelectController)

import CommentDestroyController from "./comment_destroy_controller"
Stimulus.register("comment-destroy", CommentDestroyController)

import PerformanceCoversController from "./performance_covers_controller"
Stimulus.register("performance-covers", PerformanceCoversController)

import TrackUploadController from "./track_upload_controller"
Stimulus.register("track-upload", TrackUploadController)

import PictureUploadController from "./picture_upload_controller"
Stimulus.register("picture-upload", PictureUploadController)

import TypeaheadController from "./typeahead_controller"
Stimulus.register("typeahead", TypeaheadController)

import FormTrackerController from "./form_tracker_controller"
Stimulus.register("form-tracker", FormTrackerController)

import TrackFormController from "./track_form_controller"
Stimulus.register("track-form", TrackFormController)

import PictureListController from "./picture_list_controller"
Stimulus.register("picture-list", PictureListController)

import PictureTagManagerController from "./picture_tag_manager_controller"
Stimulus.register("picture-tag-manager", PictureTagManagerController)

import MediaEncodingProgressController from "./media_encoding_progress_controller"
Stimulus.register("media-encoding-progress", MediaEncodingProgressController)

import PermalinkEditorController from "./permalink_editor_controller"
Stimulus.register("permalink-editor", PermalinkEditorController)

import LinksFormController from "./links_form_controller"
Stimulus.register("links-form", LinksFormController)

import UserBandTypesController from "./user_band_types_controller"
Stimulus.register("user-band-types", UserBandTypesController)

import SearchDropdownController from "./search_dropdown_controller"
Stimulus.register("search-dropdown", SearchDropdownController)

import PlaylistAdminController from "./playlist_admin_controller"
Stimulus.register("playlist-admin", PlaylistAdminController)

import PerformanceAdminController from "./performance_admin_controller"
Stimulus.register("performance-admin", PerformanceAdminController)

import { Controller } from "@hotwired/stimulus"
import lodash from "lodash"

import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'

export default class extends Controller {
  static values = { url: String, multiple: Boolean, displayKey: String, dropdownKey: String, enforceWhitelist: Boolean }

  connect() {
    this.element.ignoreFormTracker = true
    this.element.ignoreFormTracker = null
  }

  initialize() {
    this.initializeTagInput()
  }

  initializeTagInput() {
    let input = this.element
    let config = {
      whitelist:[],
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(',')
    }
    if (!this.multipleValue) config.mode = 'select'
    if (this.enforceWhitelistValue) {
      config.addTagOnBlur = false
    }
    if (this.displayKeyValue) {
      config.dropdown = {
        enabled: false,
        searchKeys: [this.displayKeyValue]
      }
    }
    if (this.dropdownKeyValue) {
      config.templates = {
        dropdownItem: function(item) {
          return `<div class="tagify__dropdown__item ${item.class ? item.class : ""}"
            tabindex="0"
            role="option">
            <span>${self.evaluateTemplate(item, self.dropdownKeyValue)}</span>
          </div>`
        }
      }
    }
    const tagify = new Tagify(input, config)
    let elemClasses = 'after:hidden !after:content-none w-full h-12 flex !items-center leading-5 relative py-2 px-4 rounded bg-neutral-10 !dark:bg-neutral-900 border focus:border-2 border-gray-500 overflow-x-auto focus:outline-none focus:border-primary-600 focus:ring-0 dark:text-gray-200 dark:border-gray-400 dark:focus:border-primary-200 peer'
    if (tagify.DOM) tagify.DOM.scope.classList.add(...elemClasses.split(' '))
    let controller
    let self = this

    const debouncedFetch = lodash.debounce(function(tagify, self, value) {
      tagify.whitelist = null
      controller && controller.abort()
      controller = new AbortController()
      tagify.loading(true)
      fetch(self.urlValue.replace('%25', '%').replace('%QUERY', value), { signal: controller.signal })
        .then(RES => RES.json())
        .then(function(newWhitelist) {
          let updatedData = newWhitelist
          if (self.displayKeyValue) {
            updatedData = self.formatData(updatedData, self.displayKeyValue)
          }
          tagify.whitelist = updatedData
          tagify.loading(false).dropdown.show(value)
        })
        .catch(err => {
          if (err.name !== 'AbortError') console.error('Fetch error:', err)
        })
    }, 500)

    self.dispatch('initialized', { detail: { tagify }, bubbles: true })

    tagify.on('input', (event) => {
      const value = event.detail.value
      if (value.length < 2) return
      debouncedFetch(tagify, self, value)
    })
  }

  evaluateTemplate(item, template) {
    return template.replace(/\$\{(.*?)\}/g, (_, key) => item[key.trim()] || '')
  }

  formatData(items, format) {
    // If the format is a simple string, we can directly use it as a field name
    if (format && !format.includes('${')) {
      return items.map(item => {
        return { value: item[format] || '', ...item } // Return the value for the given field
      })
    }

    // If the format includes template placeholders, use replace
    return items.map(item => {
      let value = format.replace(/\$\{([^}]+)\}/g, (_, key) => {
        return item[key] || '' // Replace placeholders with actual values
      })
      return { value, ...item } // Return the item with the formatted value
    })
  }
}

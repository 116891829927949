import { Controller } from "@hotwired/stimulus"
import PicturePreviewCache from "../helpers/picture_preview_cache"

export default class extends Controller {
  static targets = ["pictureList", "toggle", "delete", "unpersistedPicture", "fileIdentifier", "picturePreview"]

  connect() {
    this.picturePreviewCache = PicturePreviewCache
    this.initUi()
    this.bindEvents()
    this.showUnpersistedPicturesPreview()
  }

  initUi() {
    this.unpersistedPictures = this.element.querySelectorAll("li:not([data-model-id])")
  }

  async deleteItem(href) {
    const response = await fetch(href, {
      method: 'DELETE',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })

    if (!response.ok) {
      throw new Error(`HTTP error ${response.status}`)
    }
    return response
  }

  bindEvents() {
    this.element.addEventListener("picture-added", this.updateMainPicture.bind(this))
  }

  delete(event) {
    const deleteTarget = event.currentTarget

    if (deleteTarget.matches("[data-role='delete-picture']:not([data-model-id])")) {
      this.removeUnpersistedPicture(event)
    } else {
      event.preventDefault()
      if (confirm(deleteTarget.dataset.confirm)) {
        this.deleteItem(deleteTarget.getAttribute('href'))
          .then(() => {
            this.onPictureDestroySuccess(event)
          })
          .catch(error => {
            console.error('Failed to delete picture:', error)
          })
      }
    }
  }

  removeUnpersistedPicture(event) {
    event.target.closest("li").remove()
    this.updateMainPicture()
  }

  onPictureDestroySuccess(event) {
    event.stopPropagation()
    event.target.closest("li").remove()
    this.updateMainPicture()
  }

  showUnpersistedPicturesPreview() {
    this.unpersistedPictures.forEach((el) => {
      this.showPicturePreview(el)
    })
  }

  showPicturePreview(line) {
    const fileIdentifier = line.querySelector("[data-picture-upload-target='fileIdentifierInput']")?.value
    const preview = line.querySelector("[data-picture-upload-target='preview']")
    const pictureData = this.picturePreviewCache.get(fileIdentifier)

    if (pictureData) {
      preview.style.backgroundImage = `url(${pictureData})`
    }
  }

  updateMainPicture() {
    if (this.element.querySelectorAll("li").length > 1) {
      this.application.getControllerForElementAndIdentifier(this.element, "picture-tag-manager").setFirstAsMain()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "container",
    "editLink",
    "input",
    "checkButton",
    "validIndicator",
    "invalidIndicator"
  ]

  static values = {
    validateUrl: String
  }

  connect() {
    document.addEventListener('keydown', this.handleKeydown.bind(this))
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeydown.bind(this))
  }

  handleKeydown(event) {
    if (event.key === 'Escape' && this.containerTarget.style.display !== 'none') {
      this.stopEditing(event)
    }
  }

  startEditing(event) {
    event.preventDefault()
    this.containerTarget.style.display = "block"
    this.editLinkTarget.style.display = "none"
    this.setEditingState()
    this.inputTarget.select()
    this.inputTarget.focus()
  }

  stopEditing(event) {
    event.preventDefault()
    this.containerTarget.style.display = "none"
    this.editLinkTarget.style.display = "block"
    this.setNotEditingState()
  }

  async checkValidity(event) {
    event.preventDefault()
    this.setUnknownPermalinkValidityState()

    try {
      const response = await fetch(`${this.validateUrlValue}?permalink=${encodeURIComponent(this.inputTarget.value)}`, {
        headers: {
          "Accept": "application/json",
          "X-Requested-With": "XMLHttpRequest"
        }
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()

      if (data.valid) {
        this.setValidPermalinkState()
      } else {
        this.setInvalidPermalinkState()
      }
    } catch (error) {
      console.error("Error checking permalink validity:", error)
      this.setInvalidPermalinkState()
    }
  }

  setEditingState() {
    this.inputTarget.disabled = false
    this.setUnknownPermalinkValidityState()
  }

  setNotEditingState() {
    this.inputTarget.disabled = true
  }

  setValidPermalinkState() {
    this.validIndicatorTarget.style.display = "block"
    this.invalidIndicatorTarget.style.display = "none"

    const controlGroup = this.inputTarget.closest('.control-group')
    if (controlGroup) {
      controlGroup.classList.remove('error')
      controlGroup.querySelector('.help-inline')?.remove()
    }
  }

  setInvalidPermalinkState() {
    this.validIndicatorTarget.style.display = "none"
    this.invalidIndicatorTarget.style.display = "block"
  }

  setUnknownPermalinkValidityState() {
    this.validIndicatorTarget.style.display = "none"
    this.invalidIndicatorTarget.style.display = "none"
  }
}

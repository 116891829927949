import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["ensemble", "soloist", "conductor", "composer"]

  connect() {
    this.refreshStates() // Ensure correct state on load
  }

  refreshStates() {
    const isEnsemble = this.ensembleTarget.checked
    this.soloistTarget.disabled = isEnsemble
    this.conductorTarget.disabled = isEnsemble
    this.composerTarget.disabled = isEnsemble
    if (isEnsemble) {
      this.soloistTarget.checked = !isEnsemble
      this.conductorTarget.checked = !isEnsemble
      this.composerTarget.checked = !isEnsemble
    }
  }
}

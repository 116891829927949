import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["indicator"]
  static classes = ["hidden"]

  indicatePlayNow(event) {
    const path = event.detail

    this.indicatorTargets.forEach(indicator => {
      if (indicator.id === path) {
        indicator.classList.remove(this.hiddenClass)        
        this.dispatch("set", { detail: this.element })
      } else {
        indicator.classList.add(this.hiddenClass)
      }
    })
  }
}

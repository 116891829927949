import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"]

  connect() {
    this.MAIN = "main"
    this.BACKGROUND = "background"
    this.initializeTagStates()
  }

  initializeTagStates() {
    // Ensure main picture tag is visible on initial load
    const mainPicture = this.element.querySelector(`li[data-tag="${this.MAIN}"]`)
    if (mainPicture) {
      const mainTag = mainPicture.querySelector('.picture-tag-caption.main')
      if (mainTag) mainTag.classList.remove('hidden')
    }
  }

  onToggleClick(event) {
    const line = event.currentTarget.closest("li")
    const tag = event.currentTarget.dataset.tag

    // Don't allow changing tag if it's already set
    if (line.dataset.tag === tag) return

    this.setTag(line, tag)

    if (tag === this.MAIN) {
      this.element.prepend(line)
      // Show the main tag caption immediately after setting as main
      const mainTag = line.querySelector('.picture-tag-caption.main')
      if (mainTag) mainTag.classList.remove('hidden')
    }
  }

  setTag(line, tag) {
    // Hide main tag on previous main picture
    if (tag === this.MAIN) {
      const oldMain = this.element.querySelector(`li[data-tag="${this.MAIN}"]`)
      if (oldMain) {
        const mainTag = oldMain.querySelector('.picture-tag-caption.main')
        if (mainTag) mainTag.classList.add('hidden')
      }
    }

    this.resetTag(tag)
    line.dataset.tag = tag
    if (tag && line.querySelector('[data-role=picture-tag]')) {
      line.querySelector('[data-role=picture-tag]').value = tag
    }
  }

  resetTag(tag) {
    this.element.querySelectorAll('[data-role=picture-tag]').forEach(el => {
      if (el.value === tag) el.value = ''
    })
    this.element.querySelectorAll(`li[data-tag="${tag}"]`).forEach(el => {
      el.dataset.tag = ''
    })
  }
  
  setFirstAsMain() {
    const first = this.element.querySelector('li')
    this.setTag(first, this.MAIN)
  }
}

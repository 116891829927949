import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["form"]

  initialize() {
    this.element.addEventListener('turbo:submit-end', this.onFormSubmitEnd.bind(this))
    this.element.addEventListener("turbo:before-fetch-response", (event) => {
      event.preventDefault()
    })
  }

  onFormSubmitEnd(event) {
    const { fetchResponse } = event.detail
    let self = this
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()

    if (event.target.tagName.toLowerCase() === 'form') {
      fetchResponse.responseText.then(responseText => {
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = responseText
        if (fetchResponse.response.status == '422' || fetchResponse.response.status == '204') {
          try {
            const responseJson = JSON.parse(responseText)
            if (responseJson.redirect_url) {
              window.location.href = responseJson.redirect_url
            } else {
              throw new Error("TrackForm expects a response.redirect_url on success")
            }
          } catch (error) {
            self.formTarget.innerHTML = tempDiv.querySelector('form').innerHTML
            window.scrollTo(0, 0)
          }
        } else {
          const UNSUPPORTED_MEDIA_TYPE = 415
          if (fetchResponse.response.status === UNSUPPORTED_MEDIA_TYPE) {
            self.element.closest('[data-controller="track-upload"]').parentNode.innerHTML = responseText
          } else {
            self.formTarget.innerHTML = tempDiv.querySelector('form').innerHTML
          }
          window.scrollTo(0, 0) // Scroll to the top
        }
      })
    }
  }

  removeChapter(event) {
    const chapterElement = event.currentTarget.closest('.chapter')
    if (chapterElement) chapterElement.remove()
  }

  addField(event) {
    const fieldHtml = event.currentTarget.getAttribute("data-html")
    if (fieldHtml) {
      const newField = document.createRange().createContextualFragment(fieldHtml)
      event.currentTarget.parentNode.insertBefore(newField, event.currentTarget)

      const newRemoveButton = event.currentTarget.previousElementSibling.querySelector("[data-action='removeChapter']")
      if (newRemoveButton) {
        newRemoveButton.addEventListener('click', this.removeChapter.bind(this))
      }
    }
  }
}

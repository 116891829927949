import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fieldset", "stageId", "stageTypeahead", "fakeDate", "bandId", "bandTypeahead"]

  initializeTypeahead(event) {
    let tagify = event.detail.tagify
    tagify.on('input', (event) => {
      if (tagify.value[0] && event.detail.tagify.state.inputText != tagify.value[0]) {
        this.showFieldset()
        this.setId(tagify)
      } else if (tagify.value[0] && event.detail.tagify.state.inputText == tagify.value[0]) {
        this.hideFieldset(event.detail.data.id)
        this.setId(tagify, event.detail.data.id)
      }
    })
  
    tagify.on('blur', (event) => {
      if (event.detail.tagify.state.inputText.length > 0 && !tagify.value[0]) {
        this.showFieldset(tagify)
      }
    })
  
    tagify.on('dropdown:select', (event) => {
      this.hideFieldset()
      this.setId(tagify, event.detail.data.id)
    })
  }

  showFieldset() {
    if (!this.hasFieldsetTarget) return
    this.fieldsetTarget.classList.remove("hidden")
    this.stageIdTarget.value = ""
  }

  hideFieldset() {
    if (!this.hasFieldsetTarget) return
    this.fieldsetTarget.classList.add("hidden")
    this.fieldsetTarget.querySelectorAll('input, select').forEach((input) => {
      input.value = ""
    })
  }

  setId(tagify, id) {
    if (this.hasBandTypeaheadTarget && tagify.DOM.originalInput == this.bandTypeaheadTarget) {
      this.bandIdTarget.value = id || ""
    } else if (this.hasStageTypeaheadTarget && tagify.DOM.originalInput == this.stageTypeaheadTarget) {
      this.stageIdTarget.value = id || ""
    }
  }

  formatDate(event) {
    const date = new Date(event.target.value)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
    const year = date.getFullYear()

    this.fakeDateTarget.value = `${day}/${month}/${year}`
  }
}
